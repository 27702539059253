import FingerprintJS from '@fingerprintjs/fingerprintjs'
import md5 from 'blueimp-md5'

import { BASE_URL, POSTFIX, REGULAR_ROUTE, LOGIN_HEADER, REGULAR_HEADER } from '../store/constants'

const getImei = async () => {
  const fpPromise = FingerprintJS.load()
  const fp = await fpPromise
  const result = await fp.get()
  return md5(result.visitorId)
}

export default class Api {
  constructor () {
    this.IMEI = getImei()
  }

  async request (url, method, payload, type = null) {
    let response
    const params = JSON.stringify(payload)
    const headers = url === REGULAR_ROUTE ? REGULAR_HEADER : LOGIN_HEADER
    try {
      if (method === 'GET') {
        response = await fetch(`${BASE_URL}${POSTFIX}${url}?json=${params}`, {
          headers,
          method
        })
      } else if (method === 'POST' && !type) {
        response = await fetch(`${BASE_URL}${POSTFIX}${url}`, {
          headers,
          method,
          body: `json=${params}`
        })
      } else {
        response = await fetch(`${BASE_URL}${POSTFIX}${url}?json=${params}`, {
          headers,
          method
        })
      }
      const result = await response.json()
      if (result.errorType === 1) {
        // window.location.reload();
        return false
      }
      console.log(payload, result)
      return result
    } catch (error) {
      return error
    }
  }
}
