<template>
  <div class="flex flex-row h-screen antialiased text-gray-800">
    <!-- <LoginPage /> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>
