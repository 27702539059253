import { NAME_APP, LOGIN_ROUTE, SECONDS_IN_DAY } from '../constants'
import { setCookie, deleteCookie } from '../../helpers/cookies'
import Api from '../../helpers/api'

const api = new Api()

export const login = {
  namespaced: true,
  state: {
    userData: {},
    IMEI: null,
    isAuth: false,
    defaultParams: null,
    processing: false
  },
  actions: {
    async getImei ({ commit }) {
      const imei = await api.IMEI
      return commit('SET_IMEI', imei)
    },

    async signIn ({ state, commit, dispatch }, loginData) {
      if (!state.IMEI) {
        await dispatch('getImei')
      }
      const params = {
        login: loginData.login,
        password: loginData.password,
        Name_app: NAME_APP,
        IMEI: state.IMEI
      }
      try {
        commit('PROCESSING', true)
        const userData = await api.request(LOGIN_ROUTE, 'POST', params)
        if (userData && userData.errorType === 0) {
          setCookie('id_login', userData[0].id_login, { secure: true, 'max-age': SECONDS_IN_DAY })
          setCookie('TK', userData[0].TK, { secure: true, 'max-age': SECONDS_IN_DAY })
          commit('SET_USER', userData[0])
          commit('SET_AUTH', true)
          commit('SET_CONSTANT_PARAMS', { id_login: userData[0].id_login, TK: userData[0].TK, IMEI: state.IMEI, Name_app: NAME_APP })
        }
      } catch (error) {
        console.log(error)
        return error
      } finally {
        commit('PROCESSING', false)
      }
    },

    signOut ({ commit }) {
      deleteCookie('id_login')
      deleteCookie('TK')
      commit('LOGOUT')
      commit('SET_AUTH', false)
      return true
    }
  },
  mutations: {
    SET_IMEI (state, imei) {
      state.IMEI = imei
    },
    SET_USER (state, userData) {
      state.userData = userData
    },
    LOGOUT (state) {
      state.userData = {}
    },
    SET_AUTH (state, value) {
      state.isAuth = value
    },
    SET_CONSTANT_PARAMS (state, params) {
      state.defaultParams = params
    },
    PROCESSING (state, bool) {
      state.processing = bool
    }
  },
  getters: {
    isAuth (state) {
      return state.isAuth
    }
  }
}
