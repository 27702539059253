var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "flex container mx-auto my-auto rounded h-3/4 bg-cover bg-center login-section",
    },
    [
      _c("div", { staticClass: "w-full max-w-xs mx-auto my-auto" }, [
        _c(
          "form",
          { staticClass: "bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" },
          [
            _c("div", { staticClass: "font-bold text-xl text-center mb-2" }, [
              _vm._v("Medsafe App"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c(
                "label",
                {
                  staticClass: "block text-gray-700 text-sm font-bold mb-2",
                  attrs: { for: "username" },
                },
                [_vm._v("\n          Логин\n        ")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.login,
                    expression: "login",
                  },
                ],
                staticClass:
                  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                attrs: { id: "username", type: "text", placeholder: "Логин" },
                domProps: { value: _vm.login },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.login = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-6" }, [
              _c(
                "label",
                {
                  staticClass: "block text-gray-700 text-sm font-bold mb-2",
                  attrs: { for: "password" },
                },
                [_vm._v("\n          Пароль\n        ")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass:
                  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline",
                attrs: {
                  id: "password",
                  type: "password",
                  placeholder: "Пароль",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex justify-end" }, [
              _c(
                "button",
                {
                  staticClass:
                    "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline",
                  attrs: { type: "button" },
                  on: { click: _vm.tryToSignIn },
                },
                [_vm._v("\n          Войти\n        ")]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }