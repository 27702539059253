import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/LoginPage.vue'
import Main from '../view/MainPage.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: Login
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: Login,
    beforeEnter (to, from, next) {
      if (store.state.login.isAuth) {
        next({ name: 'MainPage', params: { id_login: store.state.login.userData.id_login }})
      } else {
        next()
      }
    }
  },
  {
    path: '/main',
    name: 'MainPage',
    component: Main,
  }
]

export default new VueRouter({
  // base: process.env.BASE_URL,
  routes
})
