var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "relative bg-white shadow px-5" }, [
        _c("div", { staticClass: "max-w-7xl mx-auto px-4 sm:px-6" }, [
          _c(
            "div",
            {
              staticClass:
                "flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "-mr-2 -my-2 md:hidden" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500",
                    attrs: { type: "button", "aria-expanded": "false" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Open menu"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "h-6 w-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          "stroke-width": "2",
                          stroke: "currentColor",
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M4 6h16M4 12h16M4 18h16",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "hidden md:flex items-center justify-end md:flex-1 lg:w-0",
                },
                [
                  _c("div", { staticClass: "relative" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 bg-indigo-600 group bg-white rounded-md inline-flex items-center font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                        attrs: { type: "button", "aria-expanded": "false" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openDropdownMenu.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v("Добавить окно")]),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass:
                              "text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500",
                            staticStyle: { color: "#fff" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 20 20",
                              fill: "currentColor",
                              "aria-hidden": "true",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                d: "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z",
                                "clip-rule": "evenodd",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isMenuDropdown
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.hideDropdownMenu,
                                expression: "hideDropdownMenu",
                              },
                            ],
                            staticClass:
                              "absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50",
                                        attrs: { href: "#" },
                                        on: { click: _vm.addItemC1 },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "flex-shrink-0 h-6 w-6 text-indigo-600",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24",
                                              "stroke-width": "2",
                                              stroke: "currentColor",
                                              "aria-hidden": "true",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                d: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(2),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50",
                                        attrs: { href: "#" },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "flex-shrink-0 h-6 w-6 text-indigo-600",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24",
                                              "stroke-width": "2",
                                              stroke: "currentColor",
                                              "aria-hidden": "true",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                d: "M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(3),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900",
                      attrs: { href: "#" },
                    },
                    [_vm._v(" Завершить сеанс ")]
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50",
              },
              [
                _c("div", { staticClass: "pt-5 pb-6 px-5" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between" },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "-mr-2" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500",
                            attrs: { type: "button" },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Close menu"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass: "h-6 w-6",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  "stroke-width": "2",
                                  stroke: "currentColor",
                                  "aria-hidden": "true",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    d: "M6 18L18 6M6 6l12 12",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-6" }, [
                    _c("nav", { staticClass: "grid gap-y-8" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "-m-3 p-3 flex items-center rounded-md hover:bg-gray-50",
                          attrs: { href: "#" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "flex-shrink-0 h-6 w-6 text-indigo-600",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "currentColor",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  d: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 text-base font-medium text-gray-900",
                            },
                            [_vm._v(" Analytics ")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "-m-3 p-3 flex items-center rounded-md hover:bg-gray-50",
                          attrs: { href: "#" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "flex-shrink-0 h-6 w-6 text-indigo-600",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "currentColor",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  d: "M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 text-base font-medium text-gray-900",
                            },
                            [_vm._v(" Engagement ")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "-m-3 p-3 flex items-center rounded-md hover:bg-gray-50",
                          attrs: { href: "#" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "flex-shrink-0 h-6 w-6 text-indigo-600",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "currentColor",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  d: "M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 text-base font-medium text-gray-900",
                            },
                            [_vm._v(" Security ")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "-m-3 p-3 flex items-center rounded-md hover:bg-gray-50",
                          attrs: { href: "#" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "flex-shrink-0 h-6 w-6 text-indigo-600",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "currentColor",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  d: "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 text-base font-medium text-gray-900",
                            },
                            [_vm._v(" Integrations ")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "-m-3 p-3 flex items-center rounded-md hover:bg-gray-50",
                          attrs: { href: "#" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "flex-shrink-0 h-6 w-6 text-indigo-600",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                "stroke-width": "2",
                                stroke: "currentColor",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 text-base font-medium text-gray-900",
                            },
                            [_vm._v(" Automations ")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(5),
              ]
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "desktop" },
      [
        _c(
          "grid-layout",
          {
            attrs: {
              layout: _vm.layout,
              "col-num": 3,
              "row-height": 30,
              "is-draggable": true,
              "is-resizable": true,
              "is-mirrored": false,
              "vertical-compact": true,
              margin: [10, 10],
              "use-css-transforms": true,
            },
            on: {
              "update:layout": function ($event) {
                _vm.layout = $event
              },
            },
          },
          _vm._l(_vm.layout, function (item) {
            return _c(
              "grid-item",
              {
                key: item.i,
                staticClass: "bg-white shadow rounded",
                attrs: {
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.h,
                  i: item.i,
                  "drag-allow-from": ".tab-bar",
                  "drag-ignore-from": ".no-drag",
                },
              },
              [
                _c("div", { staticClass: "tab-bar" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-close",
                    on: {
                      click: function ($event) {
                        return _vm.removeItem(item.i)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-6 w-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                          "stroke-width": "2",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M6 18L18 6M6 6l12 12",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(
                  "\n\t            " +
                    _vm._s(item.i) +
                    ": " +
                    _vm._s(item.c) +
                    "\n\t        "
                ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-start lg:w-0 lg:flex-1" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Workflow")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "h-8 w-auto sm:h-10",
          attrs: {
            src: "https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg",
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "hidden md:flex space-x-10" }, [
      _c("div", { staticClass: "relative" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-4" }, [
      _c("p", { staticClass: "text-base font-medium text-gray-900" }, [
        _vm._v("Analytics"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-1 text-sm text-gray-500" }, [
        _vm._v(
          "Get a better understanding of where your traffic is coming from."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-4" }, [
      _c("p", { staticClass: "text-base font-medium text-gray-900" }, [
        _vm._v("Engagement"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-1 text-sm text-gray-500" }, [
        _vm._v("Speak directly to your customers in a more meaningful way."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "h-8 w-auto",
        attrs: {
          src: "https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg",
          alt: "Workflow",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-6 px-5 space-y-6" }, [
      _c("div", { staticClass: "grid grid-cols-2 gap-y-4 gap-x-8" }, [
        _c(
          "a",
          {
            staticClass:
              "text-base font-medium text-gray-900 hover:text-gray-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Pricing ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "text-base font-medium text-gray-900 hover:text-gray-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Docs ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "text-base font-medium text-gray-900 hover:text-gray-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Help Center ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "text-base font-medium text-gray-900 hover:text-gray-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Guides ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "text-base font-medium text-gray-900 hover:text-gray-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Events ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "text-base font-medium text-gray-900 hover:text-gray-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Security ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "a",
          {
            staticClass:
              "w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700",
            attrs: { href: "#" },
          },
          [_vm._v(" Sign up ")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "mt-6 text-center text-base font-medium text-gray-500",
          },
          [
            _vm._v(
              "\n\t\t\t            Existing customer?\n\t\t\t            "
            ),
            _c(
              "a",
              {
                staticClass: "text-indigo-600 hover:text-indigo-500",
                attrs: { href: "#" },
              },
              [_vm._v(" Sign in ")]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }