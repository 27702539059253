// modules names
export const NAME_APP = 'WEB_division'

// api paths
export const BASE_URL = 'https://host2.medsafe.tech/'
export const POSTFIX = 'api/'

export const REGULAR_ROUTE = 'test'
export const LOGIN_ROUTE = 'login'

// utils
export const SECONDS_IN_DAY = 60 * 60 * 24

// headers
export const LOGIN_HEADER = { 'Content-Type': 'application/x-www-form-urlencoded' }
export const REGULAR_HEADER = { 'Content-Type': 'application/json' }
