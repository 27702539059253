<template>
  <section
    class="flex container mx-auto my-auto rounded h-3/4 bg-cover bg-center login-section"
  >
    <div class="w-full max-w-xs mx-auto my-auto">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="font-bold text-xl text-center mb-2">Medsafe App</div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
            Логин
          </label>
          <input
            v-model="login"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Логин"
          >
        </div>
        <div class="mb-6">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
            Пароль
          </label>
          <input
            v-model="password"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Пароль"
          >
        </div>
        <div class="flex justify-end">
          <button
            type="button"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            @click="tryToSignIn"
          >
            Войти
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',
  data () {
    return {
      login: null,
      password: null
    }
  },
  methods: {
    tryToSignIn () {
      const loginPair = { login: this.login, password: this.password }
      this.signIn(loginPair).then(()=>{
        // alert('cool')
        return this.$router.replace('main')
      })
    },
    ...mapActions('login', ['signIn', 'signOut'])
  }
}
</script>

<style>
.login-section {
  background-image: url(../assets/jumbo-image-min.jpeg);
  /* background-repeat: no-repeat;
  background-size: contain; */
}
</style>
