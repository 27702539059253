<template>
	<div style="width: 100%">
		<div class="header">
			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="relative bg-white shadow px-5">
			  <div class="max-w-7xl mx-auto px-4 sm:px-6">
			    <div class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
			      <div class="flex justify-start lg:w-0 lg:flex-1">
			        <a href="#">
			          <span class="sr-only">Workflow</span>
			          <img class="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="">
			        </a>
			      </div>
			      <div class="-mr-2 -my-2 md:hidden">
			        <button type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
			          <span class="sr-only">Open menu</span>
			          <!-- Heroicon name: outline/menu -->
			          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
			          </svg>
			        </button>
			      </div>
			      <nav class="hidden md:flex space-x-10">

			        <!-- <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900"> Pricing </a>
			        <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900"> Docs </a> -->

			        <div class="relative">

			          <!-- <button type="button" class="text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-expanded="false">
			            <span>More</span>
			            <svg class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
			            </svg>
			          </button> -->

			          <!--
			            'More' flyout menu, show/hide based on flyout menu state.

			            Entering: "transition ease-out duration-200"
			              From: "opacity-0 translate-y-1"
			              To: "opacity-100 translate-y-0"
			            Leaving: "transition ease-in duration-150"
			              From: "opacity-100 translate-y-0"
			              To: "opacity-0 translate-y-1"
			          -->
			          <!-- <div class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
			            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
			              <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Help Center</p>
			                    <p class="mt-1 text-sm text-gray-500">Get all of your questions answered in our forums or contact support.</p>
			                  </div>
			                </a>

			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Guides</p>
			                    <p class="mt-1 text-sm text-gray-500">Learn how to maximize our platform to get the most out of it.</p>
			                  </div>
			                </a>

			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Events</p>
			                    <p class="mt-1 text-sm text-gray-500">See what meet-ups and other events we might be planning near you.</p>
			                  </div>
			                </a>

			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Security</p>
			                    <p class="mt-1 text-sm text-gray-500">Understand how we take your privacy seriously.</p>
			                  </div>
			                </a>
			              </div>
			              <div class="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
			                <div>
			                  <h3 class="text-sm tracking-wide font-medium text-gray-500 uppercase">Recent Posts</h3>
			                  <ul role="list" class="mt-4 space-y-4">
			                    <li class="text-base truncate">
			                      <a href="#" class="font-medium text-gray-900 hover:text-gray-700"> Boost your conversion rate </a>
			                    </li>

			                    <li class="text-base truncate">
			                      <a href="#" class="font-medium text-gray-900 hover:text-gray-700"> How to use search engine optimization to drive traffic to your site </a>
			                    </li>

			                    <li class="text-base truncate">
			                      <a href="#" class="font-medium text-gray-900 hover:text-gray-700"> Improve your customer experience </a>
			                    </li>
			                  </ul>
			                </div>
			                <div class="mt-5 text-sm">
			                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> View all posts <span aria-hidden="true">&rarr;</span></a>
			                </div>
			              </div>
			            </div>
			          </div> -->


			        </div>
			      </nav>
			      <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
			      	<div class="relative">
			          <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
			          <button @click.prevent="openDropdownMenu" type="button" class="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 bg-indigo-600 group bg-white rounded-md inline-flex items-center font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-expanded="false">
			            <span>Добавить окно</span>
			            <!--
			              Heroicon name: solid/chevron-down

			              Item active: "text-gray-600", Item inactive: "text-gray-400"
			            -->
			            <svg style="color: #fff;" class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
			            </svg>
			          </button>

			          <!--
			            'Solutions' flyout menu, show/hide based on flyout menu state.

			            Entering: "transition ease-out duration-200"
			              From: "opacity-0 translate-y-1"
			              To: "opacity-100 translate-y-0"
			            Leaving: "transition ease-in duration-150"
			              From: "opacity-100 translate-y-0"
			              To: "opacity-0 translate-y-1"
			          -->
			          <div v-click-outside="hideDropdownMenu" v-if="isMenuDropdown" class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
			            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
			              <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
			                <a @click="addItemC1" href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <!-- Heroicon name: outline/chart-bar -->
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Analytics</p>
			                    <p class="mt-1 text-sm text-gray-500">Get a better understanding of where your traffic is coming from.</p>
			                  </div>
			                </a>

			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <!-- Heroicon name: outline/cursor-click -->
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Engagement</p>
			                    <p class="mt-1 text-sm text-gray-500">Speak directly to your customers in a more meaningful way.</p>
			                  </div>
			                </a>

			                <!-- <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Security</p>
			                    <p class="mt-1 text-sm text-gray-500">Your customers&#039; data will be safe and secure.</p>
			                  </div>
			                </a>

			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Integrations</p>
			                    <p class="mt-1 text-sm text-gray-500">Connect with third-party tools that you&#039;re already using.</p>
			                  </div>
			                </a>

			                <a href="#" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
			                  <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
			                  </svg>
			                  <div class="ml-4">
			                    <p class="text-base font-medium text-gray-900">Automations</p>
			                    <p class="mt-1 text-sm text-gray-500">Build strategic funnels that will drive your customers to convert</p>
			                  </div>
			                </a> -->

			              </div>

			              <!-- <div class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
			                <div class="flow-root">
			                  <a href="#" class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
			                    <svg class="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
			                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
			                    </svg>
			                    <span class="ml-3">Watch Demo</span>
			                  </a>
			                </div>

			                <div class="flow-root">
			                  <a href="#" class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
			                    <svg class="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
			                    </svg>
			                    <span class="ml-3">Contact Sales</span>
			                  </a>
			                </div>
			              </div> -->
			            </div>
			          </div>
			        </div>
			        <a href="#" class="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"> Завершить сеанс </a>
			        <!-- <a href="#" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"> Sign up </a> -->
			      </div>
			    </div>
			  </div>

			  <!--
			    Mobile menu, show/hide based on mobile menu state.

			    Entering: "duration-200 ease-out"
			      From: "opacity-0 scale-95"
			      To: "opacity-100 scale-100"
			    Leaving: "duration-100 ease-in"
			      From: "opacity-100 scale-100"
			      To: "opacity-0 scale-95"
			  -->
			  <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
			    <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
			      <div class="pt-5 pb-6 px-5">
			        <div class="flex items-center justify-between">
			          <div>
			            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow">
			          </div>
			          <div class="-mr-2">
			            <button type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
			              <span class="sr-only">Close menu</span>
			              <!-- Heroicon name: outline/x -->
			              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			              </svg>
			            </button>
			          </div>
			        </div>
			        <div class="mt-6">
			          <nav class="grid gap-y-8">
			            <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
			              <!-- Heroicon name: outline/chart-bar -->
			              <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                <path stroke-linecap="round" stroke-linejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
			              </svg>
			              <span class="ml-3 text-base font-medium text-gray-900"> Analytics </span>
			            </a>

			            <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
			              <!-- Heroicon name: outline/cursor-click -->
			              <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
			              </svg>
			              <span class="ml-3 text-base font-medium text-gray-900"> Engagement </span>
			            </a>

			            <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
			              <!-- Heroicon name: outline/shield-check -->
			              <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
			              </svg>
			              <span class="ml-3 text-base font-medium text-gray-900"> Security </span>
			            </a>

			            <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
			              <!-- Heroicon name: outline/view-grid -->
			              <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
			              </svg>
			              <span class="ml-3 text-base font-medium text-gray-900"> Integrations </span>
			            </a>

			            <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
			              <!-- Heroicon name: outline/refresh -->
			              <svg class="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
			                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
			              </svg>
			              <span class="ml-3 text-base font-medium text-gray-900"> Automations </span>
			            </a>
			          </nav>
			        </div>
			      </div>
			      <div class="py-6 px-5 space-y-6">
			        <div class="grid grid-cols-2 gap-y-4 gap-x-8">
			          <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700"> Pricing </a>

			          <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700"> Docs </a>

			          <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700"> Help Center </a>

			          <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700"> Guides </a>

			          <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700"> Events </a>

			          <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700"> Security </a>
			        </div>
			        <div>
			          <a href="#" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"> Sign up </a>
			          <p class="mt-6 text-center text-base font-medium text-gray-500">
			            Existing customer?
			            <a href="#" class="text-indigo-600 hover:text-indigo-500"> Sign in </a>
			          </p>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		</div>
		<div class="desktop">
      	<grid-layout
	            :layout.sync="layout"
	            :col-num="3"
	            :row-height="30"
	            :is-draggable="true"
	            :is-resizable="true"
	            :is-mirrored="false"
	            :vertical-compact="true"
	            :margin="[10, 10]"
	            :use-css-transforms="true"
	    >
	        <grid-item class="bg-white shadow rounded" v-for="item in layout"
	                   :x="item.x"
	                   :y="item.y"
	                   :w="item.w"
	                   :h="item.h"
	                   :i="item.i"
	                   :key="item.i"
	                   drag-allow-from=".tab-bar"
                     drag-ignore-from=".no-drag">
	            <div class="tab-bar"></div>
	            <div class="tab-close" @click="removeItem(item.i)">
	            	<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
								  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
								</svg>
	            </div>
	            {{item.i}}: {{item.c}}
	        </grid-item>
	    </grid-layout>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from "vuedraggable";
import Vue from "vue";
import VueGridLayout from 'vue-grid-layout';


export default {
  name: "functional",
  display: "Functional third party",
  order: 17,
  components: {
    draggable,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  data() {
    return {
			layout: [
	          {"x":0,"y":0,"w":1,"h":7,"i":"0","c":"c1"},
	          {"x":1,"y":0,"w":2,"h":7,"i":"1","c":"c1"},
	          {"x":0,"y":4,"w":3,"h":7,"i":"2","c":"c2"},
	      ],
	    isMobile: false,
	    isMenuDropdown: false,
	    index: 0,
	    colNum: 3,

    };
  },
  methods: {
  	openDropdownMenu () {
  		if (!this.isMenuDropdown)
	  		setTimeout(()=>{
	  			this.isMenuDropdown = true;
	  		},25)
  	},
  	hideDropdownMenu () {
  		if (this.isMenuDropdown)
  			this.isMenuDropdown = false;
  	},
  	removeItem: function (val) {
        const index = this.layout.map(item => item.i).indexOf(val);
        this.layout.splice(index, 1);
    },
    addItemC1: function () {
            // Add a new item. It must have a unique key!
            this.layout.push({
                x: (this.layout.length * 2) % (this.colNum || 3),
                y: this.layout.length + (this.colNum || 3), // puts it at the bottom
                w: 1,
                h: 7,
                i: this.index,
                c: "c1"
            });
            // Increment the counter to ensure key is always unique.
            this.index++;
        },
  },
  mounted() {
      // this.$gridlayout.load();
      this.index = this.layout.length;
  },
};
</script>


<style>
.header {
	width: 100%;
	/*height: 70px;*/
}

.desktop {
	width: 100%;
	height: calc(100vh - 100px);
}

.vue-grid-item {
	/*border: 1px solid black;
	border-radius:  6px;*/
}

.tab-bar {
	height: 30px;
	width: calc(100% - 30px);
	background: gray;
	background: #e7e7e7;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=);
	/*border-top-right-radius: 0.25rem;*/
	border-top-left-radius: 0.25rem;
}

.tab-close {
	height: 30px;
	width: 30px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	background: #e7e7e7;
}
.tab-close svg {
	margin:  3px;
}
</style>
